/* CatalogPage.css */

.catalog-page {
  padding: 20px;
  margin: 0;
  width: 100%; /* Ensure the catalog section spans the full width */
  box-sizing: border-box; /* Include padding in width calculations */
}

.catalog-page h1 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 3.2em;
}

.catalog-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 items per row */
  gap: 20px; /* Space between items */
  padding: 0 10px; /* Add padding on left and right for alignment */
}

.product-card {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.product-card:hover {
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.product-image {
  width: 100%; /* Image spans the width of the card */
  height: auto;
  border-radius: 8px;
}

.product-name {
  font-size: 1.2em;
  margin: 10px 0;
}

.product-price {
  color: #28a745;
  font-weight: bold;
}

.product-description {
  font-size: 0.9em;
  color: #555;
}

/* Modal Styles */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  border-radius: 8px;
  padding: 30px;
  max-width: 900px; /* Adjusted modal width */
  width: 90%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  display: flex; /* Flexbox layout for content */
  flex-direction: column; /* Column layout for title and content */
  position: relative;
}

.modal-name {
  font-size: 2em; /* Larger font for the product name */
  margin-bottom: 20px;
  text-align: center;
}

.modal-body {
  display: flex; /* Flexbox layout for image and description */
  align-items: flex-start;
  gap: 20px; /* Spacing between image and description */
}

.modal-image {
  max-width: 50%; /* Image takes half the modal width */
  height: auto;
  border-radius: 8px;
}

.modal-description {
  font-size: 1.2em; /* Larger description text */
  color: #555;
  flex: 1; /* Ensure description takes up remaining space */
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 20px;
  background: none;
  border: none;
  font-size: 1.8em;
  cursor: pointer;
  color: #333;
}

.close-modal:hover {
  color: red;
}
/* Adjust for different screen sizes */
@media (max-width: 992px) {
  .catalog-grid {
    grid-template-columns: repeat(3, 1fr); /* 3 items per row */
  }
}

@media (max-width: 768px) {
  .catalog-grid {
    grid-template-columns: repeat(2, 1fr); /* 2 items per row */
  }
}

@media (max-width: 480px) {
  .catalog-grid {
    grid-template-columns: 1fr; /* 1 item per row */
  }
}
